import React from "react";

import GlobalLayout from "../components/page/GlobalLayout";

export default function ThirdPartyTest() {
  const windowGlobal = typeof window === "undefined" ? null : window;
  React.useEffect(() => {
    if (
      windowGlobal &&
      windowGlobal.parent &&
      windowGlobal.document &&
      windowGlobal.document.cookie
    ) {
      if (/thirdparty=yes/.test(document.cookie)) {
        windowGlobal.parent.postMessage("MM:3PCsupported", "*");
      } else {
        windowGlobal.parent.postMessage("MM:3PCunsupported", "*");
      }
      windowGlobal.document.cookie =
        "thirdparty=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  }, [windowGlobal]);

  return (
    <GlobalLayout>
      <h1>
        This page is used to test third party cookie support for the Rhombus
        Console embedable players do not delete.
      </h1>
    </GlobalLayout>
  );
}
